
footer {
    background-color: #f8f8f800;
    padding: 30px 0 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100px;
  }
  
  .footer-links a {
    margin-right: 10px;
    color:#e9bd0ce3;
    text-decoration: none;
    font-size: 1.7em;
    font-family: 'Bebas Neue', sans-serif;
  }
  
  .footer-social-icons a {
    margin-right: 10px;
    color: #e9bd0ce3;
    text-decoration: none;
    font-size: 1.8em;
  }
  
  .footer-text {
    margin: 0 1.5em;
    color: #e9bd0ce3;
    font-size: 21px;
    font-family: 'Bebas Neue', sans-serif;
  }

  @media (max-width: 768px) {
    footer {
      padding: 20px 0 80px !important;
      flex-direction: column;
      align-items: center;
    }
    .footer-social-icons a svg {
      width: 60px;
      height: 33px;
    }
    .footer-social-icons {
      margin: 16px 0 5px !important;
    }
  }
  