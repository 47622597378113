@media only screen and (max-width: 768px) {
    #contact-form {
        flex-direction: column !important;
    }
    #form-data {
        width: 92% !important;
        padding: 1em !important;
    }
    .head-form {
        width: 100% !important;
    }
    .head-form h3 {
        margin: 1em !important;
        text-align: center;
    }
    .body_partners p {
        width: 100% !important;
    }
    .img-responsive {
        width: 95% !important;
        margin: 0 auto !important;
        display: block;
    }
}

#contact-page h2{
    margin: 0 !important;
}

#contact-form {
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 3em 0;
    font-family: 'Bebas Neue', sans-serif;
    align-items: baseline;
}

input[class="form-control"], textarea {
    background: #0f9e48 !important;
    color: white !important;
    font-family: 'Bebas Neue', sans-serif;
    padding: 0.4em;
    font-size: 1.2em;
    margin: 0.4em 0;
}

.btn {
    padding: 1em 4em;
    background: #fb4c4b;
    border: none;
    color: white;
    cursor: pointer;
    margin: 1em 0;
}

.mails li h4 {
    padding: 0;
    margin: 0;
    color: #0f9e48;
    font-size: 1.8em;
}

.mails li p {
    margin: 0.4em 0.5em;
    font-size: 1.3em;
}

.form-label {
    font-size: 1.5em;
}

.head-form{
    width: 46%;
}

.head-form h3 {
    font-size: 1.9em;
    margin: 0;
}

#form-data {
    width: 30%;
    padding: 3em;
}

#form-data .mb-3 {
    display: flex;
    flex-direction: column;
}

.show-image2{
    width: 100%;
}

.img-responsive {
    width: 100%;
}