@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
.brand-logo-container {
    display: flex;
    align-items: center;
  }
  
  .brand-logo {
    width: 200px;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .brand-logo {
      height: 70px;
      width: auto;
    }
  }
  
  .app__brand-name {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 90px;
    display: inline-flex;
    align-items: center;
  }
  
  .header-link {
    color: inherit;
    text-decoration: none;
  }
  
  @media (max-width: 500px) {
    .app__brand-name {
      font-size: 12px;
      flex-wrap: wrap;
    }
    
    .brand-logo-container {
      flex-direction: column;
      align-items: center;
    }
  }
  
  .app__brand-name {
    
    color: rgb(251, 255, 251);
  }
  

  .navbar {
    display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
 
  }
  
  .navbar-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .navbar-menu {
    display: none;
  }
  
  .navbar-menu.open {
    display: block;
  }
  
  .navbar-menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: 30px;
    display: flex;
  }
  
  .navbar-menu-list li {
    margin-right: 10px;
  }
  
  .navbar-menu-list li:last-child {
    margin-right: 0;
  }
  
  .navbar-menu-list li a {
    color: #e9bd0ce3;
    text-decoration: none;
    display: block;
    font-size: 1.8em;
  }
  
  @media (max-width: 768px) {
    .navbar-menu-list li a {
      font-size: 1.3em !important;
    }

    .navbar-menu-list {
      flex-direction: column;
      margin-left: 0;
    }
  
    .navbar-menu-list li {
      margin-bottom: 10px;
      margin-right: 0
    }
}  
  