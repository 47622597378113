.sign-in {
    border: none;
    background-color: transparent;
    left: 0em;
    position: relative;
}
.sign-in > img {
    height: 30px;
    width: auto;
}
.sign-out {
    padding: 5px 10px;
    border-radius: 5px;
    color: #88dded;
    border: 1px solid #1c2c4c;
    background-color: #1c2c4c;
    font-weight: 600;
}
  
/* welcome page */
.welcome {
    padding: 30px;
    text-align: center;
    margin-top: 40px;
    color: #7cc5d9;
}
.welcome :is(h2, p, img) {
    margin-bottom: 20px;
}
  
/* chat component */
.messages-wrapper {
    padding: 4em 15px 80px 33px;
    overflow-y: auto;
    width: 100%;
}
.chat-bubble {
    border-radius: 20px 20px 20px 0;
    padding: 15px;
    background-color: #fcdf01;
    color: #000;
    width: max-content;
    max-width: calc(100% - 50px);
    box-shadow: -2px 2px 1px 1px #0f9e48;
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}
.chat-bubble.right {
    margin-left: auto;
    border-radius: 20px 20px 0 20px;
    background-color: #fff;
    box-shadow: -2px 2px 1px 1px #88dded;
}
.chat-bubble__left {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
}
.user-name {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 0.9rem;
    color: #179545;
    text-align: left;
}
.user-date {
    font-size: 0.8em;
    display: inline-block;
    text-align: right;
    font-weight: bolder;
    color: #e20512;
}
.user-message {
    word-break: break-word;
    margin: 0.4em 0;
    text-align: left;
}
.message-time {
    display: block;
    text-align: right;
}
  
/* send message */
.send-message {
    position: absolute;
    bottom: 0px;
    width: 95%;
    padding: 9px 9px 9px 28px;
    background-color: #000;
    display: flex;
}
.send-message > textarea {
    height: 40px;
    padding: 10px 10px;
    border-radius: 5px 0 0 5px;
    border: none;
    flex-grow: 1;
    background-color: white;
    color: #1c2c4c;
    font-size: 1rem;
}
.send-message > textarea:placeholder {
    color: #ddd;
}
.send-message > :is(textarea, button):focus {
    outline: none;
    border-bottom: 1px solid #7cc5d9;
}
.send-message > button {
    padding: 5px 10px;
    border-radius: 0 5px 5px 0;
    color: #fff;
    border: 1px solid #fff;
    background-color: #169449;
    font-weight: 600;
}

.navBox {
    height: 100%;
    background: url("/src/assets/images/background_player.66a3e5c.svg"),linear-gradient(90deg,#14a76c,#ffe400 50%,#ff652f);
    background-size: cover;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    width: 0;
    transition: all 1s;
}

.navBox h3{
    color:white;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.navBox p.txt_ini {
    padding: 1em;
    color: #fff;
    font-weight: bold;
    text-align: center;
}
  
.open {
    width: 25%;
    z-index: 2;
}

.collapse {
    transition: ease-out 1s;
    -moz-transition: ease-out 1s;
    -webkit-transition: ease-out 1s;
    -o-transition: ease-out 1s;
}

.btnChat{
    position: fixed !important;
    bottom: 0 !important;
    border-radius: 0 !important;
    background-color: #169449 !important;
    color: white !important;
    left: 0 !important;
    width: auto;
}

.logo {
    position: absolute;
    right: 0;
    top: 0;
}

.header_chat {
    position: absolute;
    width: 100%;
    top: 0;
    right: -7px;
    display: flex;
    background: #000000bf;
}

.header_chat h3 {
    text-align: center;
    margin: 0 auto;
    padding: 1em;
    width: 100%;
}

@media(max-width:767px) {
    .btnChat {
        width: 100% !important;
    }
    .open {
        width: 90%;
        text-align: center;
        z-index: 1050;
        height: 94.1%;
    }
}