/* HomePage.css */
@media only screen and (max-width: 768px) {
  .home-parts {
    flex-direction: column;
  }
  .home-events, .home-news, .home-slides {
    width: 100% !important;
  }
  .showcase-body {
    height: 542px !important;
  }
}

.hero-container {
    background: url('/src/assets/images/background1.svg') no-repeat center center fixed;
    background-size: cover;
    /* Other styling properties for the hero section */
}

.home-page h2 {
  margin: 0 !important;
}

.home-parts {
  display: flex;
  justify-content: center;
}

.home-events, .home-news {
  width: 24%;
}

.home-slides {
  width: 50%;
}

.events_body {
  background-color: white;
  height: 710px;
  margin-top: 15px;
}

.events_body h3 {
  text-align: center;
  font-size: 1.6em;
  font-family: 'Bebas Neue', sans-serif;
  padding: 0 1.5em;
}

.events_body .content-slider img {
  width: 100%;
  max-width: 270px;
}

.body_in {
  display: block;
  border: 6px solid;
  height: 99%;
}

.event-data {
  padding: 1em;
  font-family: 'Bebas Neue', sans-serif;
}

.event-data .title {
  font-size: 1.7em;
  margin-left: 2px;
  width: 100%;
  display: block;
  text-align: center;
  background-color: #1a7020;
  color: white;
  font-weight: bold;
}

.event-data .body {
  text-align: center;
  display: inline-block;
  width: 100%;
  font-size: 1.3em;
  padding: .4em 0; 
}

.showcase-body {
  font-family: bebas neue;
  margin: 0 auto;
  text-align: center;
  background: #fff;
  border: 6px solid;
  border-top: none;
  height: 352px;
}

.showcase-body h3, .news_body h3 {
  font-size: 1.5em;
  margin: 0.6em 2em;
  font-family: bebas neue;
  text-align: center;
}

.btn_cntct {
  padding: 0.5em 3em;
  background: black;
  color: white;
  border: 1px solid;
  border-radius: 8px;
  cursor: pointer;
}

.news_body {
  background-color: #fff;
  height: 712px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;
  text-align: center;
}

.news_body img {
  width: 100%;
}

.news_body p {
  margin: 1em;
  font-family: bebas neue;
  font-size: 1.3em;
  text-align: justify;
}

.news_body span {
  margin: 1em;
  font-family: bebas neue;
  font-size: 1em;
  color: #0b5566;
  display: block;
}