@media only screen and (max-width: 768px) {
  .home-slides .swiper-slide img{
    height: 190px !important;
  }
}

.slider {
  position: relative;
  overflow: hidden;
  margin-top: 15px;
}

/*
.home-slides .slider::before {
  content: '';
  background-repeat: repeat;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
*/
.content-slider {
  margin: 0 auto;
  text-align: center;
}

.content-slider img {
  max-width: 760px;
}

/* Rest of the CSS code */
.home-slides .swiper-slide img{
  object-fit: contain;
  height: 310px;
}

.home-slides .slider {
  border: 6px solid;
  background: linear-gradient(90deg, #008000, #ff0, #f00);
  padding: 1em 0;
}

.slider-wrapper {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.slide {
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Center the image based on the height */
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}


