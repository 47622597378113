@media only screen and (max-width: 768px) {
    .content_partners {
        flex-direction: column !important;
    }
    .body_partners {
        padding: 2.5em 0em !important;
        width: 100% !important;
    }
    .body_partners p {
        width: 97% !important;
    }
    .imgs_partners {
        padding: 1em 0 !important;
        justify-content: center !important;
    }
    .img-responsive {
        width: 95% !important;
        margin: 0 auto !important;
        display: block;
    }
}

.partners-page h2{
    margin: 0 !important;
}

.body_partners {
    background: #0f9e48;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 2.5em 0.5em;
    width: 70%;
}

.body_partners p {
    color: #fcf818;
    width: 93%;
    font-size: 1.8em;
    font-family: 'Bebas Neue', sans-serif;
    text-align: center;
    margin: 0.5em;
}

.imgs_partners {
    padding: 2em 0 0;
    background: #fcdf01;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: first baseline;
}

.imgs_partners .img {
    display: flex;
    flex-direction: row;
    width: 140px;
    padding: 1em;
    flex-wrap: wrap;
}

.imgs_partners .img img {
    width: 100%;
    transition: transform .5s;
}

.imgs_partners .img img:hover {
    transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
}

.partners {
    padding: 1em;
    padding: 0.8em 0 !important;
    background: linear-gradient(#0f9e48, #ffaf17, #0f9e48);
    border: 5px solid;
}

.show-image2{
    width: 100%;
}

.img-responsive {
    width: 100%;
}

.content_partners {
    display: flex;
    flex-direction: row-reverse;
}

.imgs_partners .img h3 {
    font-family: 'Bebas Neue', sans-serif;
    text-align: center;
    color: #0f9e48;
    width: 100%;
}