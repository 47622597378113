@media only screen and (max-width: 768px) {
    .slider {
        height: auto !important;
    }
    .swiper-slide {
        width: 100% !important;
        transition-property: transform;
    }
    .centova-streaming-player {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 3vh;
        background-color: #fff3;
        border-radius: 10px;
        padding: 0;
        width: 100%;
        margin: 0 auto;
    }
    .image-placeholder {
        display: flex;
        flex-direction: column;
        margin-right: 0;
    }
    .metadata-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0;
        margin-right: 12px;
    }
    .shows-title {
        font-family: 'Bebas Neue', sans-serif;
        font-size: 40px !important;
        display: block;
        text-align: center;
        color: #fff;
        padding: 0.4em;
    }
    .flag-item .time p {
        font-size: 0.6em !important;
    }
}

.shows {
    width: 100%;
    min-height: 440px;
    margin-top: 15px;
    background-color: #fcbe18;
    padding: 1em 0 0 !important;
}
  
.swiper-wrapper {
    display: flex;
    transition: transform 0.3s ease-in-out;
}
  
.swiper {
    flex: 0 0 33%;
    display: flex;
    justify-content: center;
    align-items: center;
}



.content-slide {
    padding: .6em;
}
  
.head-slide {
    margin: 0 auto;
    text-align: center;
    width: 140px;
    border: 1px solid;
    height: 140px;
    overflow: hidden;
    border-radius: 52em;
    display: flex;
    align-content: center;
    z-index: 2;
    box-shadow: 0px 0px 0px 5px #fff;
    position: relative;
}

.body-slide {
    position: relative;
    top: -4.7em;
    background: white;
    padding: 4.3em 2em 3em;
}

.body-slide h2 {
    text-align: center;
    font-family: 'Bebas Neue', sans-serif;
}

.body-slide p {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 100;
}

.body-slide > p {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 100;
    min-height: 103px;
    overflow-y: auto;
    max-height: 105px;
}

.show-image {
    max-height: 100%;
    object-fit: contain; /* Center the image based on the height */
}
  
.prev-button,
.next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
}
  
.prev-button {
    left: 10px;
}
  
.next-button {
    right: 10px;
}

.swiper-scrollbar.swiper-scrollbar-horizontal {
    background: black;
}

.swiper-scrollbar-drag {
    height: 20px;
    color: white;
    background-color: #169449;
}

.swiper-button-prev, .swiper-button-next {
    color: #179545;
}

.schedule {
    flex-direction: revert;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
}

.schedule-item {
    display: flex;
    flex-direction: column;
    width: 49%;
}

.schedule-item .header {
    text-align: center;
}

.schedule-item .country-item {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.country-item h3 {
    text-align: center;
    background: #000;
    color: white;
    padding: .5em;
    margin: 0.2em 0;
    font-size: 0.8em;
}

.flags {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.flag-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button-more {
    display: flex;
    padding: 1em;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 45%;
}

.btns {
    cursor: pointer;
    background: red;
    border: none;
    color: white;
    line-height: 1.5em;
}

.flag-item img {
    width: 31%;
}

.flag-item .time p {
    font-size: 1em;
}