html{
  max-width: 1920px;
  width: 100%;
  margin:0 auto;
}
body {
  margin: 0;
  padding: 0;
  background: url('/src/assets/images/bg_radioital.jpg') no-repeat center center fixed;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.9); /* Set background color with transparency */
  font-family: sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a{
  text-decoration: none;
  font-family: 'Bebas Neue', sans-serif;
}

.dread_yellow{
	color: #ffff00;
	text-shadow:1px 1px 1px black;
}

.dread_green{
	color: #57ed6e;
	text-shadow:1px 1px 1px black;
}

.buttons_modal {
  display: flex;
}

.button {
  border-radius: 6px;
  padding: 0.5em 3em;
  margin: 1.1em 0.9em;
  background: #6a3e89;
  color: #a8c720;
  border: none;
  font-size: 1.2em;
  font-weight: bold;
  font-family: BEBAS;
  cursor: pointer;
  display: flex;
}

.modal-background {
  display: flex;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #0a0a0adb;
  height: 100%;
  width: 98%;
  margin: 0 auto;
  z-index: 1051;
}

.modal-card {
  margin: 0 auto;
  display: block;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.modal-card img{
  height: 85vh;
  padding-top: 1em;
}

@media only screen and (max-width: 768px) {
  .modal-card img{
    width: 90% !important;
    height: auto !important;
    padding-top: 1em;
  }
  .button {
    background: #6a3e89;
    border: none;
    border-radius: 6px;
    color: #a8c720;
    cursor: pointer;
    font-family: BEBAS;
    font-size: 1.2em;
    font-weight: 700;
    margin: 1.1em .9em;
    padding: .5em 2em !important;
  }
}