@media only screen and (max-width: 768px) {
    .history {
        flex-direction: column !important;
        padding: 1em 1em !important;
    }
    .history p {
        font-size: 22px !important;
        text-align: center !important;
    }
    .mission {
        padding: 1em 1em !important;
        flex-direction: column;
    }
    .mission-right {
        width: 100% !important;
        padding: 1em 0 !important;
        margin: 0 auto !important;
    }
    .vision {
        padding: 1em 1em !important;
        flex-direction: column;
    }
    .vision-left {
        width: 100% !important;
        padding: 1em 0 !important;
        margin: 0 auto !important;
    }
    .vision-right {
        width: 100% !important;
    }
    .mission p{
        font-size: 22px !important;
        text-align: center !important;
    }
    .vision p{
        font-size: 22px !important;
        text-align: center !important;
    }
    .img-responsive {
        width: 95% !important;
        margin: 0 auto !important;
        display: block;
    }
}

.about-page h2{
    margin: 0 !important;
}

.history {
    background: #fff;
    padding: 1em 12em;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}

.history-left {
    width: 100%;
    padding: 1em;
    transform: rotate(0deg);
    background-color: #ff5400;
    margin: 0 0.1em;
    filter: drop-shadow(0px 4px 3px #000);
}

.history-right {
    width: 100%;
    margin: 0 1em;
}

.history p {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 27px;
    display: block;
    text-align: right;
    color: #000;
}

.mission {
    background: linear-gradient(#e30513, #ff4800);
    padding: 2em 5em;
    display: flex;
}

.vision {
    background: linear-gradient(#ff4800, #e30513);
    padding: 2em 5em;
    display: flex;  
}

.vision-left {
    width: 47%;
    padding: 1em 1em;
    background-color: #fff;
    margin: 2em 1.1em auto;
    filter: drop-shadow(0px 4px 3px #000);
}

.vision-right {
    width: 72%;
}

.mission p{
    font-family: 'Bebas Neue', sans-serif;
    font-size: 27px;
    display: block;
    text-align: left;
    color: #fff;
}

.mission-left {
    width: 100%;
}

.mission-right {
    width: 39%;
    padding: 1em 1em;
    background-color: #fff;
    margin: 2em 1.1em auto;
    filter: drop-shadow(0px 4px 3px #000);
}

.vision p{
    font-family: 'Bebas Neue', sans-serif;
    font-size: 27px;
    display: block;
    text-align: right;
    color: #fff;
}

.img-responsive {
    width: 100%;
}